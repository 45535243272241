import Log from '../Log';
import { getElement } from '../UIHelpers';
import { domReady, guid } from '../helpers';
import RuntimeConfig from '../Runtime';
import { Modes } from '../Modes';
import { Types as MessagesTypes } from '@sunbit-dev/purchase-common/src/enums/SdkMessages';

export interface ICheckoutOptions {
  token: string;
  onReplaceUrl: (options: { url: string }) => void;
  onGetCurrentHref: () => string;
  onCompleted: (data: IMessageResponse) => void;
  onCanceled: (data: IMessageResponse) => void;
  onUserCancelled: (data: IMessageResponse) => void;
  onPlaceInFlow: (data: IMessageResponse) => void;
  onPurchaseVerified: (data: IMessageResponse) => void;
  checkoutContext?: string;
  tv?: string;
  t?: string;
  customerVerified?: string;
}

// Coming from webpack definition
declare var __IS_UNSAFE__: boolean;
declare var __EPAY_DEMO_URL__: string;
declare var __EPAY_SANDBOX_URL__: string;
declare var __EPAY_PROD_URL__: string;
declare var __EPAY_DEMO_V2_URL__: string;
declare var __EPAY_PROD_V2_URL__: string;
declare var __LOAN_APPLICATION_DEMO_URL__: string;
declare var __LOAN_APPLICATION_SANDBOX_URL__: string;
declare var __LOAN_APPLICATION_PROD_URL__: string;

const isUnsafe = __IS_UNSAFE__ || false;

const EPAY_URL = {
  [Modes.DEMO_MODE]: __EPAY_DEMO_URL__,
  [Modes.SANDBOX_MODE]: __EPAY_SANDBOX_URL__,
  [Modes.PRODUCTION_MODE]: __EPAY_PROD_URL__,
};

const EPAY_URL_V2 = {
  [Modes.DEMO_MODE]: __EPAY_DEMO_V2_URL__,
  [Modes.SANDBOX_MODE]: __EPAY_SANDBOX_URL__,
  [Modes.PRODUCTION_MODE]: __EPAY_PROD_V2_URL__,
};

const LOAN_APPLICATION_URL = {
  [Modes.DEMO_MODE]: __LOAN_APPLICATION_DEMO_URL__,
  [Modes.SANDBOX_MODE]: __LOAN_APPLICATION_SANDBOX_URL__,
  [Modes.PRODUCTION_MODE]: __LOAN_APPLICATION_PROD_URL__,
};

export interface IMessageResponse {
  completed: boolean;
  statusType: StatusTypes;
}

export interface IMessageResponseWithContext extends IMessageResponse {
  checkoutContext: string;
  purchaseId: number;
  type: MessagesTypes;
  url?: string;
}

enum StatusTypes {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  Voided = 'Voided',
}

interface IReceiveMessageEvent {
  data: IMessageResponseWithContext;
}

const sunbitOnlineSdkTemplate = `
  <div data-epay-wrap>
    <iframe data-epay-iframe src='about:blank' frameBorder='0' scrolling='no' allow='encrypted-media'></iframe>
  </div>
`;

export class Epay {
  private checkouts: { [key: string]: ICheckoutOptions } = {}; // tslint:disable-line:no-empty
  private wrappingElement: HTMLElement;
  private iframe: HTMLIFrameElement;

  public init({ onInitFinish }: { onInitFinish?: () => void }) {
    Log.debug('initilizing epay');
    if (!this.isIframeInjected()) {
      this.injectElements({ onInitFinish });
      window.addEventListener('message', this.receiveMessages, false);
    }
  }

  public checkout(checkoutOptions: ICheckoutOptions) {
    Log.debug('Checkout with:', checkoutOptions);
    checkoutOptions.checkoutContext = guid();
    // const self = this;
    this.checkouts[checkoutOptions.checkoutContext] = checkoutOptions;
    const load = () => {
      this.iframe.removeEventListener('load', load);
      const initMessage = { type: 'INIT', checkoutContext: checkoutOptions.checkoutContext };
      Log.debug('iframe loaded sending INIT Message with', initMessage);
      this.iframe.contentWindow.postMessage(initMessage, '*');
    };
    this.iframe.addEventListener('load', load);

    let mode = RuntimeConfig.getMode();
    let url = `${LOAN_APPLICATION_URL[mode]}?token=${checkoutOptions.token}`;

    if (checkoutOptions.token.length > 8) {
      let baseUrl = RuntimeConfig.getV2() ? EPAY_URL_V2[mode] : EPAY_URL[mode];
      const tokenVerified = +checkoutOptions.tv === 1 ? `&tv=${checkoutOptions.tv}` : ''; // === 1
      const type = checkoutOptions.t === 'm' ? `&t=${checkoutOptions.t}` : '';
      const customerVerified =
        +checkoutOptions.customerVerified === 1 ? `&customerVerified=${checkoutOptions.customerVerified}` : '';
      url = `${baseUrl}?token=${encodeURIComponent(checkoutOptions.token)}${tokenVerified}${type}${customerVerified}`;
    }

    // using replace to avoid creating an history entry
    this.iframe.contentWindow.location.replace(url);
    this.open();
  }

  private receiveMessages = ({ data }: IReceiveMessageEvent) => {
    Log.debug('receiveMessages:', data);
    if (!data || !data.checkoutContext || !this.checkouts[data.checkoutContext]) {
      Log.debug('ignoring: unknown message', data);
      return;
    }
    const { checkoutContext, ...restData } = data;
    const checkoutOptions = this.checkouts[checkoutContext];
    if (data.type === MessagesTypes.DONE) {
      if (checkoutOptions.onCompleted instanceof Function) {
        checkoutOptions.onCompleted(restData);
      }
      this.close();
    } else if (data.type === MessagesTypes.PURCHASE_VERIFIED) {
      if (checkoutOptions.onPurchaseVerified instanceof Function) {
        checkoutOptions.onPurchaseVerified(restData);
      }
    } else if (data.type === MessagesTypes.PLACE_IN_FLOW) {
      if (checkoutOptions.onPlaceInFlow instanceof Function) {
        checkoutOptions.onPlaceInFlow(restData);
      }
    } else if (data.type === MessagesTypes.USER_CANCEL) {
      if (checkoutOptions.onUserCancelled instanceof Function) {
        checkoutOptions.onUserCancelled(restData);
      }
      this.close();
    } else if (data.type === MessagesTypes.SESSION_EXPIRED) {
      location.reload();
    } else if (data.type === MessagesTypes.CANCEL) {
      if (checkoutOptions.onCanceled instanceof Function) {
        checkoutOptions.onCanceled(restData);
      }
      this.close();
    } else if (data.type === MessagesTypes.REPLACE_URL) {
      if (checkoutOptions.onReplaceUrl instanceof Function) {
        checkoutOptions.onReplaceUrl({ url: restData.url });
      }
    } else if (data.type === MessagesTypes.GET_CURRENT_HREF) {
      if (checkoutOptions.onGetCurrentHref instanceof Function) {
        const href = checkoutOptions.onGetCurrentHref();
        const messagePayload = {
          href,
          type: MessagesTypes.RESPONSE_CURRENT_HREF,
          checkoutContext: checkoutOptions.checkoutContext,
        };

        this.iframe.contentWindow.postMessage(messagePayload, '*');
      }
    }
  };

  private isIframeInjected() {
    return !!this.iframe;
  }

  private close() {
    Log.debug('hiding epay');
    // using replace to avoid creating an history entry
    this.iframe.contentWindow.location.replace('about:blank');
    this.wrappingElement.classList.remove('visible');
    document.body.classList.remove('no-scroll');
  }

  private open() {
    Log.debug('showing epay');
    this.wrappingElement.classList.add('visible');
    document.body.classList.add('no-scroll');
  }

  private injectElements({ onInitFinish }: { onInitFinish?: () => void }) {
    Log.debug('injectElement():');
    if (this.isIframeInjected()) {
      Log.debug('Elements are already in place, nothing to do...');
      return;
    }
    domReady(() => {
      Log.debug('Injecting elements');
      const placeholderElement = document.createElement('div');
      placeholderElement.innerHTML = sunbitOnlineSdkTemplate;
      this.wrappingElement = getElement('[data-epay-wrap]', placeholderElement);
      this.iframe = getElement('[data-epay-iframe]', placeholderElement) as HTMLIFrameElement;
      document.body.appendChild(this.wrappingElement);
      onInitFinish?.();
    });
  }
}

export function setEpayUrl(mode: string, url: string) {
  if (isUnsafe) {
    EPAY_URL[mode] = url;
    EPAY_URL_V2[mode] = url;
  }
}

export function setLoanApplicationUrl(mode: string, url: string) {
  if (isUnsafe) {
    LOAN_APPLICATION_URL[mode] = url;
  }
}
